import { IconButton, XIcon, Label, ArrowLeftIcon, Stack, pxToRem } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import type { MouseEventHandler } from 'react'

const SelectedItemContainer = styled(Stack)(({ theme }) => ({
  height: pxToRem(40),
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing['1x'],
  backgroundColor: 'rgba(163, 163, 150, 0.17)',
  paddingLeft: theme.spacing['4x'],
  paddingRight: theme.spacing['2x'],
  borderRadius: theme.radii.full,
  whiteSpace: 'nowrap',
  userSelect: 'none',
}))
const RemoveItemButton = styled.button(({ theme }) => ({
  width: theme.sizes['6x'],
  height: theme.sizes['6x'],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: theme.radii.full,
}))

type SelectedItemProps = {
  label: string
  labelSize?: 'sm' | 'md'
  onItemRemove: MouseEventHandler<HTMLButtonElement>
}

export function SelectedItem({ label, onItemRemove, labelSize = 'md', ...rest }: SelectedItemProps) {
  return (
    <SelectedItemContainer {...rest}>
      <Label size={labelSize}>{label}</Label>
      <RemoveItemButton onClick={onItemRemove}>
        <XIcon size={16} />
      </RemoveItemButton>
    </SelectedItemContainer>
  )
}
export function BackButton({ onClick }: { onClick: () => void }) {
  return <IconButton onClick={onClick} label="Back" icon={ArrowLeftIcon} />
}

export function ClearButton({ onClick }: { onClick: () => void }) {
  return <IconButton size="sm" onClick={onClick} icon={XIcon} label="Clear" />
}
