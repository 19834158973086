import styled from '@emotion/styled'
import { LoadingDots, Stack } from '@qasa/qds-ui'
import { Error } from '@qasa/app/src/components/error'
import type { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import type { SearchHistoryProps } from '../search-history'
import { SearchHistoryHeading } from '../search-history.parts'
import { useSearchHistoryContext } from '../search-history-context'

import { SavedSearchesListItem } from './saved-searches-list-item'

function SavedSearchesListWrapper(props: PropsWithChildren) {
  return <Stack role="menu" as="ul" {...props} />
}

const Wrapper = styled(Stack)({
  alignItems: 'flex-start',
})
const ListWrapper = styled(SavedSearchesListWrapper)({
  width: '100%',
})
export function SavedSearches({ onListItemPress }: SearchHistoryProps) {
  const { t } = useTranslation('find_home', { keyPrefix: 'saved_searches' })

  const { savedSearches, isLoading, error } = useSearchHistoryContext()

  if (isLoading) {
    return <LoadingDots />
  }

  if (error) {
    return <Error error={error} />
  }

  if (!savedSearches || savedSearches.length === 0) {
    return null
  }

  return (
    <Wrapper gap="4x">
      <SearchHistoryHeading>{t('list_title')}</SearchHistoryHeading>
      <ListWrapper>
        {savedSearches.map((savedSearch) => (
          <SavedSearchesListItem
            onListItemPress={onListItemPress}
            key={savedSearch.id}
            savedSearch={savedSearch}
          />
        ))}
      </ListWrapper>
    </Wrapper>
  )
}
