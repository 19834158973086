import { useMutation } from '@apollo/client'
import { type SavedSearchesQuery } from '@qasa/graphql'
import { useState } from 'react'
import { useToastContext } from '@qasa/app/src/contexts/toasts'
import { UPDATE_SAVED_SEARCH } from '@qasa/app/src/features/find-home/search-history/saved-searches/saved-searches.gql'
import { useTranslation } from 'react-i18next'

import { parseSavedSearch, useFormattedSavedSearch } from '../../saved-searches/saved-searches.utils'

export type SavedSearchesListItemProps = {
  savedSearch: SavedSearchesQuery['savedSearch'][number]
  onListItemPress?: () => void
}

export const useSavedSearchesListItem = ({ savedSearch }: SavedSearchesListItemProps) => {
  const { id, searchParams, monitoring: isMonitoring } = savedSearch
  const { t } = useTranslation('find_home', { keyPrefix: 'saved_searches' })
  const [tCommons] = useTranslation('commons')
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const searchSummary = useFormattedSavedSearch({ savedSearch })
  const savedSearchFilters = parseSavedSearch({ searchParams })

  const { addToast } = useToastContext()
  const handleError = () => addToast({ message: tCommons('unexpected_error') })

  const [updateSavedSearch] = useMutation(UPDATE_SAVED_SEARCH, {
    onCompleted: (response) => {
      const errors = response.updateSavedSearch?.errors
      if (errors) {
        handleError()
      } else {
        addToast({ message: t('toggle_monitoring.success_toast_message'), timeout: 2000 })
      }
    },
    onError: handleError,
    optimisticResponse: {
      __typename: 'MutationRoot',
      updateSavedSearch: {
        __typename: 'UpdateSavedSearchPayload',
        savedSearch: {
          ...savedSearch,
          monitoring: !isMonitoring,
        },
        errors: null,
      },
    },
  })
  return {
    updateSavedSearch: async () =>
      updateSavedSearch({
        variables: { savedSearchId: id, input: { monitoring: !isMonitoring } },
      }),
    savedSearchFilters,
    setIsDeleteDialogOpen,
    isDeleteDialogOpen,
    searchSummary,
    isMonitoring,
  }
}
