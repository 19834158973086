import { HomeIndexSearchOrderByEnum, HomeIndexSearchOrderDirectionEnum } from '@qasa/graphql'

import { BRAND_CONFIG } from '../../../config/brand-configurations'

import type { FindHomeFilterValues } from './use-find-home-filters'

export const DEFAULT_FILTER_VALUES: FindHomeFilterValues = {
  minMonthlyCost: null,
  maxMonthlyCost: null,
  currency: BRAND_CONFIG.allowedCurrencies[0],
  minSquareMeters: null,
  maxSquareMeters: null,
  minRoomCount: null,
  maxRoomCount: null,
  earliestMoveIn: null,
  earlyAccess: '',
  minRentalLength: null,
  homeTypes: [],
  contractTypes: [],
  sharedHome: [],
  furnished: [],
  wheelchairAccessible: [],
  householdSize: null,
  searchAreas: [],
  page: 1,
  rules: [],
  order: HomeIndexSearchOrderDirectionEnum.descending,
  orderBy: HomeIndexSearchOrderByEnum.published_or_bumped_at,
  uids: null,
}
