import type { ForwardedRef } from 'react'
import { forwardRef } from 'react'
import { Label, SearchIcon } from '@qasa/qds-ui'
import styled from '@emotion/styled'

import type { AreaSearchDialogTriggerProps } from '../types'
import { usePathname } from '../../../../vendor/next'

import { selectedAreaToLabel } from './area-search.utils'
import { PlaceholderWrapper, useAreaSearchDialogTrigger } from './area-search-dialog-trigger.shared'
import { SelectedItem } from './area-search-dialog.parts'

const StyledAreaSearchButton = styled.button<{ isGray: boolean }>(({ theme, isGray }) => ({
  minHeight: theme.sizes['12x'],
  width: '100%',
  paddingInline: theme.sizes['1x'],
  display: 'flex',
  alignItems: 'center',
  border: '1px solid',
  gap: theme.spacing['1x'],
  borderRadius: theme.radii.full,
  borderColor: theme.colors.border.default,
  textAlign: 'left',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  overflowX: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  ...(isGray && {
    borderColor: theme.colors.border.subtle,
    backgroundColor: theme.colors.bg.brandTertiary,
  }),
}))

export const ButtonText = styled.span<{ hasSelectedAreas: boolean }>(({ theme, hasSelectedAreas }) => ({
  color: theme.colors.core.black,
  ...theme.typography.body.sm,
  ...(hasSelectedAreas && { ...theme.typography.label.sm }),
}))
export const AreaSearchDialogTrigger = forwardRef(
  (props: AreaSearchDialogTriggerProps, forwardedRef: ForwardedRef<HTMLButtonElement>) => {
    const { selectedAreas, onChange, placeholder: placeholderProp, ...rest } = props
    const pathname = usePathname()
    /* TODO: Fix this */
    const isFindHomeSearch = pathname.includes('/find-home')
    const { hasSelectedAreas, placeholder, removeSearchAreaAtIndex } = useAreaSearchDialogTrigger({
      selectedAreas,
      onChange,
      isPlaceholderShort: isFindHomeSearch,
      placeholder: placeholderProp,
    })
    return (
      <StyledAreaSearchButton ref={forwardedRef} isGray={isFindHomeSearch} {...rest}>
        {!hasSelectedAreas && (
          <PlaceholderWrapper direction="row" alignItems="center" gap="3x">
            <SearchIcon size={20} />
            <Label as={'span'} style={{ lineHeight: 'normal' }}>
              {placeholder}
            </Label>
          </PlaceholderWrapper>
        )}
        <>
          {selectedAreas.map((selectedOption, index) => (
            <SelectedItem
              key={selectedOption.name}
              label={selectedAreaToLabel(selectedOption)}
              onItemRemove={(ev) => {
                ev.stopPropagation()
                removeSearchAreaAtIndex(index)
              }}
            />
          ))}
        </>
      </StyledAreaSearchButton>
    )
  },
)
