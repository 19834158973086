import { HistoryIcon, Stack } from '@qasa/qds-ui'

import { SearchHistoryLink } from '../search-history-link'
import { SearchHistorySummary } from '../search-history.parts'
import type { SearchHistoryProps } from '../search-history'
import { useFormattedFilterValues } from '../../saved-searches/saved-searches.utils'
import type { PreviousSearchType } from '../search-history-context'

type PreviousSearchesListItemProps = SearchHistoryProps & {
  previousSearch: PreviousSearchType
}
export function PreviousSearchesListItem({ onListItemPress, previousSearch }: PreviousSearchesListItemProps) {
  const { filterValues } = previousSearch
  const searchSummary = useFormattedFilterValues({ filterValues })

  return (
    <Stack>
      <SearchHistoryLink filterValues={filterValues} onClick={onListItemPress}>
        <HistoryIcon size={16} style={{ flexShrink: 0 }} />
        <SearchHistorySummary searchSummary={searchSummary} />
      </SearchHistoryLink>
    </Stack>
  )
}
