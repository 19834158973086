import styled from '@emotion/styled'

export const AreaSearchDialogInput = styled.input({
  border: 'none',
  flex: 1,
  padding: 0,
  height: '100%',
  ':focus': {
    outline: 'none',
  },
})
