import { createSerializer } from 'nuqs'
import { parse, stringify } from 'query-string'

import type { FindHomeFilterValues } from '../use-find-home-filters'
import { searchParamsParsers } from '../search-params-parsers'

/**
 * Encode the query string in a way that is consistent and
 * sorted to avoid SEO issues with duplicate content.
 *
 * Unfortunately we can't use `URLSearchParams` since has some
 * inconsistencies with the way it encodes values, e.g. it encodes
 * commas in arrays as `%2C` instead of `,`. Also tilde (~)
 * doesn't have to get encoded.
 *
 * See: https://github.com/whatwg/url/issues/491
 */
export const formatQueryString = (queryString: string) => {
  if (!queryString) {
    return ''
  }

  const parsedQuery = parse(queryString, { arrayFormat: 'comma' })

  // Go through each item in the parsed query that is an array and sort it
  for (const key in parsedQuery) {
    if (Array.isArray(parsedQuery[key])) {
      parsedQuery[key] = parsedQuery[key].sort()
    }
  }

  const stringifiedQuery = stringify(parsedQuery, { arrayFormat: 'comma', skipEmptyString: true })
  return `?${stringifiedQuery}`
}

const serializer = createSerializer(searchParamsParsers)
export const getFindHomeQueryString = ({ filterValues }: { filterValues: Partial<FindHomeFilterValues> }) => {
  const query = serializer(filterValues)

  return formatQueryString(query)
}
