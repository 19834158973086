import styled from '@emotion/styled'
import { SlashSeparatedData } from '@qasa/app/src/components/slash-separated-data'
import { useBreakpointValue, Paragraph, Heading, Stack } from '@qasa/qds-ui'

const BreakAllParagraph = styled(Paragraph)({ wordBreak: 'break-word' })

const Wrapper = styled(Stack)({
  flex: 1,
})
type Props = {
  searchSummary: string[]
}
export function SearchHistorySummary({ searchSummary }: Props) {
  const isMdOrAbove = useBreakpointValue({ base: false, md: true })

  const [searchArea, ...restOfSummary] = searchSummary

  if (isMdOrAbove) {
    return <SlashSeparatedData gap="2x" data={searchSummary} />
  }

  return (
    <Wrapper>
      <BreakAllParagraph numberOfLines={1}>{searchArea}</BreakAllParagraph>
      <SlashSeparatedData textSize="sm" gap="2x" data={restOfSummary} />
    </Wrapper>
  )
}

export const SearchHistoryHeading = styled(Heading)(({ theme }) => ({
  ...theme.typography.title['2xs'],
  marginLeft: theme.spacing['2x'],
}))
