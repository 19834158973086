import { Spacer, Stack } from '@qasa/qds-ui'
import {
  AutocompleteLoader,
  AutocompleteNoResults,
} from '@qasa/app/src/components/autocomplete/autocomplete.shared'
import { AutocompleteOption } from '@qasa/app/src/components/autocomplete/autocomplete-option.web'

import type { AreaSearchProps } from '../types'
import { SearchHistory } from '../../search-history/search-history'
import type { SelectedArea } from '../../find-home.types'

import { SelectedItem } from './area-search-dialog.parts'
import { AreaSearchDialogListWrapper } from './area-search-dialog-list-wrapper'
import { selectedAreaToLabel, selectedAreaToSublabel } from './area-search.utils'

type AreaSearchDialogListProps = AreaSearchProps & {
  inputValue: string
  onInputValueChange: (newValue: string) => void
  areaSuggestions: SelectedArea[]
  isLoading: boolean
  onClose?: () => void
  shouldShowSearchHistory?: boolean
}
export function AreaSearchDialogList({
  value,
  onChange,
  inputValue,
  onInputValueChange,
  areaSuggestions,
  isLoading,
  onClose,
  shouldShowSearchHistory,
}: AreaSearchDialogListProps) {
  const handleRemoveItem = (itemIndex: number) => {
    const clonedArray = [...value]
    clonedArray.splice(itemIndex, 1)
    onChange(clonedArray)
  }

  const isSearching = Boolean(inputValue)
  const hasResults = Boolean(areaSuggestions.length)

  if (isLoading) {
    return <AutocompleteLoader />
  }

  if (isSearching && !hasResults) {
    return <AutocompleteNoResults />
  }

  if (isSearching) {
    return (
      <AreaSearchDialogListWrapper>
        {areaSuggestions.map((option, index) => (
          <AutocompleteOption
            key={`${option.name}-${index}`}
            role="option"
            label={selectedAreaToLabel(option)}
            sublabel={selectedAreaToSublabel(option)}
            onPress={() => {
              onInputValueChange('')
              onChange([...value, option])
              onClose && onClose()
            }}
          />
        ))}
      </AreaSearchDialogListWrapper>
    )
  }

  if (!value.length && shouldShowSearchHistory) {
    return (
      <>
        <Spacer size="6x" />
        <SearchHistory onListItemPress={onClose} />
      </>
    )
  }

  return (
    <>
      <Spacer size="4x" />
      <Stack gap={'3x'} direction="row" wrap="wrap">
        {value.map((item, index) => (
          <SelectedItem
            key={item.name}
            label={item.name}
            labelSize="sm"
            onItemRemove={() => handleRemoveItem(index)}
          />
        ))}
      </Stack>
      <Spacer size="12x" />
      {shouldShowSearchHistory && <SearchHistory onListItemPress={onClose} />}
    </>
  )
}
