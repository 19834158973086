import isEqual from 'lodash/isEqual'

import { DEFAULT_FILTER_VALUES } from '../default-filter-values'
import type { FindHomeFilterValues } from '../use-find-home-filters'

type GetIsFilterActiveParams<TFilterKey extends keyof FindHomeFilterValues> = {
  filterKey: TFilterKey
  currentValue: FindHomeFilterValues[TFilterKey] | undefined
}

export const getIsFilterActive = <TFilterKey extends keyof FindHomeFilterValues>({
  filterKey,
  currentValue,
}: GetIsFilterActiveParams<TFilterKey>) => {
  return !isEqual(currentValue, DEFAULT_FILTER_VALUES[filterKey])
}
