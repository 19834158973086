'use client'

import type { PropsWithChildren } from 'react'
import { createContext, useContext } from 'react'
import type { ApolloError } from '@apollo/client'
import { useQuery } from '@apollo/client'
import type { SavedSearchesQuery } from '@qasa/graphql'
import { HomeRentalTypeEnum } from '@qasa/graphql'
import { useRegionContext } from '@qasa/app/src/contexts/region'
import { usePersistentStorage } from '@qasa/app/src/hooks/use-persistent-storage'
import isEqual from 'lodash/isEqual'
import { SAVED_SEARCHES } from '@qasa/app/src/features/find-home/search-history/saved-searches/saved-searches.gql'

import { useAuthContext } from '../../../context/auth-context'
import type { FindHomeFilterValues } from '../filters/use-find-home-filters'

import { getIsSearchFilterValuesEmpty, getSearchFilterValues } from './search-history.utils'

const NUMBER_OF_PREVIOUS_SEARCHES = 5

export type PreviousSearchType = {
  filterValues: Partial<FindHomeFilterValues>
}
type SearchHistoryContextType =
  | {
      savedSearches: SavedSearchesQuery['savedSearch'] | undefined
      previousSearches: PreviousSearchType[] | undefined

      createPreviousSearch: (search: FindHomeFilterValues) => void
      isLoading: boolean
      error: ApolloError | undefined
    }
  | undefined

const SearchHistoryContext = createContext<SearchHistoryContextType>(undefined)

export function SearchHistoryProvider({ children }: PropsWithChildren) {
  const { region } = useRegionContext()
  const { isAuthenticated } = useAuthContext()
  const [previousSearches, setPreviousSearches] = usePersistentStorage<PreviousSearchType[]>(
    'previousSearches',
    undefined,
    { initializeWithValue: false },
  )

  //NOTE: Temporary code to "backfill" countryCode for previous searches - Kevin Brandhild 2024-03-07
  const previousSearchesWithCountryCode = previousSearches?.map(({ filterValues }) => {
    return {
      filterValues: {
        ...filterValues,
        searchAreas: filterValues.searchAreas?.map((searchArea) => {
          //NOTE: A safety precaution in the cases the locale would be international
          const resolvedRegion = region === 'international' ? 'se' : region
          return { ...searchArea, countryCode: searchArea.countryCode ?? resolvedRegion }
        }),
      },
    }
  })

  const createPreviousSearch = (filterValues: FindHomeFilterValues) => {
    if (getIsSearchFilterValuesEmpty(filterValues)) {
      return
    }

    const previousSearchFilterValues = getSearchFilterValues(filterValues)

    const newSearch = { filterValues: previousSearchFilterValues }
    if (previousSearches) {
      const updatedSearches = [
        newSearch,
        ...previousSearches.filter((search) => !isEqual(search, newSearch)),
      ].slice(0, NUMBER_OF_PREVIOUS_SEARCHES)
      setPreviousSearches(updatedSearches)
    } else {
      setPreviousSearches([newSearch])
    }
  }

  const {
    data,
    loading: isLoading,
    error,
  } = useQuery(SAVED_SEARCHES, {
    skip: !isAuthenticated,
    variables: {
      rentalType: HomeRentalTypeEnum.long_term,
    },
  })

  const savedSearches = data?.savedSearch

  return (
    <SearchHistoryContext.Provider
      value={{
        savedSearches,
        previousSearches: previousSearchesWithCountryCode,
        createPreviousSearch,
        isLoading,
        error,
      }}
    >
      {children}
    </SearchHistoryContext.Provider>
  )
}

export const useSearchHistoryContext = () => {
  const context = useContext(SearchHistoryContext)

  if (context === undefined) {
    throw new Error('useSearchHistoryContext must be used within a SearchHistoryProvider')
  }
  return context
}
