import { Stack } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next'

import type { AreaSearchDialogTriggerProps } from '../types'

export const useAreaSearchDialogTrigger = ({
  onChange,
  selectedAreas,
  isPlaceholderShort,
  placeholder,
}: AreaSearchDialogTriggerProps & { isPlaceholderShort: boolean }) => {
  const { t } = useTranslation('find_home', { keyPrefix: 'area_search' })
  const hasSelectedAreas = Boolean(selectedAreas.length)

  const removeSearchAreaAtIndex = (index: number) => {
    const clonedArray = [...selectedAreas]
    clonedArray.splice(index, 1)
    onChange(clonedArray)
  }

  const getPlaceholder = () => {
    if (placeholder) {
      return placeholder
    } else if (!hasSelectedAreas) {
      const context = isPlaceholderShort ? 'short' : undefined
      return t('input_placeholder.default', { context })
    }
    return t('input_placeholder.add_another_area')
  }
  return {
    removeSearchAreaAtIndex,
    placeholder: getPlaceholder(),
    hasSelectedAreas,
  }
}

export const PlaceholderWrapper = styled(Stack)(({ theme }) => ({
  marginLeft: theme.spacing['3x'],
}))
