import omit from 'lodash/omit'

import type { FindHomeFilterValues } from '../filters/use-find-home-filters'

export const getSearchFilterValues = (filterValues: FindHomeFilterValues) => {
  return omit(filterValues, 'page', 'order', 'orderBy', 'uids', 'rentalType')
}

export const getIsSearchFilterValuesEmpty = (filterValues: FindHomeFilterValues) => {
  const searchFilterValues = getSearchFilterValues(filterValues)

  return Object.values(searchFilterValues).every(
    (value) => value === null || (Array.isArray(value) && value.length === 0),
  )
}
