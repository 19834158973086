import { createParser, parseAsInteger, parseAsString, parseAsStringEnum, parseAsArrayOf } from 'nuqs/server'
import {
  CurrencyEnum,
  EarlyAccessSearchFilterEnum,
  HomeIndexSearchOrderByEnum,
  HomeIndexSearchOrderDirectionEnum,
} from '@qasa/graphql'

import { BRAND_CONFIG } from '../../../config/brand-configurations'

import { parseSearchAreaQuery, stringifySearchArea } from './utils/search.utils'

const parseAsSearchArea = createParser({
  parse: (value: string) => {
    return parseSearchAreaQuery(value)
  },
  serialize: (value) => {
    return stringifySearchArea(value)
  },
})

// NOTE: Whenever you add some new filter here,
// please also add it to the `robots.ts` file if it shouldn't be indexed by search engines.
export const searchParamsParsers = {
  minMonthlyCost: parseAsInteger,
  maxMonthlyCost: parseAsInteger,
  currency: parseAsStringEnum<CurrencyEnum>(Object.values(CurrencyEnum)).withDefault(
    BRAND_CONFIG.allowedCurrencies[0],
  ),
  minSquareMeters: parseAsInteger,
  maxSquareMeters: parseAsInteger,
  minRoomCount: parseAsInteger,
  maxRoomCount: parseAsInteger,
  earliestMoveIn: parseAsString,
  earlyAccess: parseAsStringEnum<EarlyAccessSearchFilterEnum | ''>(
    Object.values(EarlyAccessSearchFilterEnum),
  ).withDefault(''),
  minRentalLength: parseAsInteger,
  homeTypes: parseAsArrayOf(parseAsString).withDefault([]),
  contractTypes: parseAsArrayOf(parseAsString).withDefault([]),
  sharedHome: parseAsArrayOf(parseAsString).withDefault([]),
  furnished: parseAsArrayOf(parseAsString).withDefault([]),
  wheelchairAccessible: parseAsArrayOf(parseAsString).withDefault([]),
  householdSize: parseAsInteger,
  searchAreas: parseAsArrayOf(parseAsSearchArea).withDefault([]),
  page: parseAsInteger.withDefault(1),
  rules: parseAsArrayOf(parseAsString).withDefault([]),
  order: parseAsStringEnum<HomeIndexSearchOrderDirectionEnum>(
    Object.values(HomeIndexSearchOrderDirectionEnum),
  ).withDefault(HomeIndexSearchOrderDirectionEnum.descending),
  orderBy: parseAsStringEnum<HomeIndexSearchOrderByEnum>(
    Object.values(HomeIndexSearchOrderByEnum),
  ).withDefault(HomeIndexSearchOrderByEnum.published_or_bumped_at),
  uids: parseAsArrayOf(parseAsString),
}
