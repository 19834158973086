import { Divider, Stack } from '@qasa/qds-ui'
import { useRegionContext } from '@qasa/app'

import { SavedSearches } from './saved-searches/saved-searches'
import { RecommendedSearches, useRecommendedSearches } from './recommended-searches'
import { PreviousSearches } from './previous-searches'
import { useSearchHistoryContext } from './search-history-context'

export type SearchHistoryProps = {
  onListItemPress?: () => void
}
export function SearchHistory({ onListItemPress }: SearchHistoryProps) {
  const { savedSearches, previousSearches } = useSearchHistoryContext()
  const { region } = useRegionContext()
  const recommendedSearches = useRecommendedSearches(region)
  const hasSavedSearches = Boolean(savedSearches?.length)
  const hasPreviousSearches = Boolean(previousSearches?.length)
  const hasRecommendedSearches = Boolean(recommendedSearches?.length)

  return (
    <Stack gap="8x">
      <SavedSearches onListItemPress={onListItemPress} />
      {hasSavedSearches && hasPreviousSearches && <Divider />}
      <PreviousSearches onListItemPress={onListItemPress} />
      {(hasSavedSearches || hasPreviousSearches) && hasRecommendedSearches && <Divider />}
      <RecommendedSearches onListItemPress={onListItemPress} />
    </Stack>
  )
}
