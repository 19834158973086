import type { SelectedArea } from '../../find-home.types'

export const stringifySearchArea = (selectedArea: SelectedArea): string => {
  const name = selectedArea.name.replace(/\s/g, '_')
  const city = selectedArea.city ? `~${selectedArea.city.replace(/\s/g, '_')}` : '~'
  const countryCode = selectedArea.countryCode ? `~${selectedArea.countryCode}` : '~'

  return name + city + countryCode
}

export const parseSearchAreaQuery = (searchAreaQuery: string): SelectedArea => {
  // Handle characters that have been encoded with windows-1252 encoding.
  // Blocket still sends this encoding in some cases. TODO: Ask them to fix it.
  const commonWindows1252Chars = {
    '%E4': 'ä',
    '%E5': 'å',
    '%F6': 'ö',
    '%C4': 'Ä',
    '%C5': 'Å',
    '%D6': 'Ö',
  }
  Object.entries(commonWindows1252Chars).forEach(([key, value]) => {
    searchAreaQuery = searchAreaQuery.replaceAll(key, value)
  })

  const [name, city, countryCode] = searchAreaQuery
    .split('~')
    // Replace underscores with whitespace
    .map((str: string) => str.replace(/_/g, ' '))

  return { name: name as string, city, countryCode }
}
