import { useState } from 'react'
import { Dialog } from '@qasa/app/src/components/dialog'
import styled from '@emotion/styled'
import { IconButton, XIcon } from '@qasa/qds-ui'
import { useTranslation } from 'react-i18next'

import type { AreaSearchProps } from '../types'
import { useAreaSuggestions } from '../use-area-suggestions'

import { AreaSearchDialogList } from './area-search-dialog-list'
import { AreaSearchDialogTrigger } from './area-search-dialog-trigger'
import { BackButton } from './area-search-dialog.parts'
import { getUnselectedAreaOptions } from './area-search.utils'
import { AreaSearchDialogInput } from './area-search-dialog-input'
import { AreaSearchDialogBody } from './area-search-dialog-body'

const Header = styled(Dialog.Header)({
  paddingTop: 0,
  paddingBottom: 0,
})
const StyledDialogBody = styled(AreaSearchDialogBody)(({ theme }) => ({
  padding: theme.sizes['2x'],
}))

type AreaSearchDialogProps = AreaSearchProps & {
  shouldShowSearchHistory?: boolean
  shouldAutofocus?: boolean
}

export function AreaSearchDialog({
  value,
  onChange,
  shouldShowSearchHistory,
  shouldAutofocus = true,
  placeholder,
}: AreaSearchDialogProps) {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation('find_home', { keyPrefix: 'area_search' })
  const [searchQuery, setSearchQuery] = useState('')
  const { isLoadingSuggestions, suggestedAreas } = useAreaSuggestions({
    searchQuery,
    suggestionsLimit: 12,
  })

  const nonSelectedAreas = getUnselectedAreaOptions({ areaSuggestions: suggestedAreas, selectedAreas: value })

  return (
    <Dialog
      mode="fullScreen"
      isOpen={isOpen}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          setSearchQuery('')
        }
        setIsOpen(isOpen)
      }}
      trigger={
        <AreaSearchDialogTrigger selectedAreas={value} onChange={onChange} placeholder={placeholder} />
      }
      isCloseButtonVisible={false}
    >
      <Header>
        <BackButton onClick={() => setIsOpen(false)} />
        <AreaSearchDialogInput
          placeholder={t('input_placeholder.default')}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={shouldAutofocus}
          value={searchQuery}
          onChange={(event) => setSearchQuery(event.target.value)}
        />
        {searchQuery && <IconButton icon={XIcon} label="Clear" onClick={() => setSearchQuery('')} />}
      </Header>
      <StyledDialogBody>
        <AreaSearchDialogList
          value={value}
          onChange={onChange}
          areaSuggestions={nonSelectedAreas}
          isLoading={isLoadingSuggestions}
          inputValue={searchQuery}
          onInputValueChange={setSearchQuery}
          onClose={() => setIsOpen(false)}
          shouldShowSearchHistory={shouldShowSearchHistory}
        />
      </StyledDialogBody>
    </Dialog>
  )
}
