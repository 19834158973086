import { HomeTypeEnum } from '@qasa/graphql'

// NOTE: Keep these home types in sync with mapHomeTypes in find-home.shared.ts
export const FILTERABLE_HOME_TYPES =
  // NOTE: For Qasa.com we want to avoid separating the filters options.
  // If we want to add a new filter-item we should add it for all "platforms".
  [
    HomeTypeEnum.apartment,
    HomeTypeEnum.house,
    HomeTypeEnum.cottage,
    HomeTypeEnum.corridor,
    HomeTypeEnum.other,
  ]
